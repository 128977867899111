import * as React from 'react';
import styled from 'styled-components';

import Row from '../components/Basic/Row';
import Col from '../components/Basic/Col';
import Container from '../components/Basic/Container';
import Footer from '../components/Layout/Footer';
import Seo from '../components/seo';

const List = styled.ol`
  padding-left: 20px;
`;

const ListItem = styled.li`
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.925rem;
  letter-spacing: 0.05rem;
  line-height: 160%;
  margin: 0;
  white-space: normal;
  padding: 10px 0;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const PrivacyPolicyPage = () => (
  <>
    <Seo title="JukonMedia.pl - Polityka Prywatności" />
    <Container>
      <Row style={{ padding: '30px 0' }}>
        <Col md={12}>
          <Section>
            <h2>Polityka prywatności i plików cookies portalu internetowego jukonmedia.pl należącego do JukonMedia Mariusz Mischke.</h2>
            <p>
              Bezpieczeństwo i ochrona prywatności Użytkowników portalu jukonmedia.pl są dla nas priorytetowe. Z uwagi na wagę i znaczenie tych danych przygotowaliśmy dla Użytkowników naszego portalu zbiór zasad informujący, w jaki sposób gromadzimy, przetwarzamy wykorzystujemy i przechowujemy dane osobowe Użytkowników portalu jukonmedia.pl.
            </p>
          </Section>
          <Section>
            <h2>Przetwarzanie danych osobowych Użytkowników portalu jukonmedia.pl</h2>
            <List>
              <ListItem>
                Administratorem danych osobowych Użytkowników portalu jukonmedia.pl jest JukonMedia Mariusz Mischke z siedzibą w Bydgoszczy, ul. Bruska 11B, 85-422 Bydgoszcz, NIP 953-11-34-672, REGON:091326133, email: mariusz@jukonmedia.pl.
              </ListItem>
              <ListItem>
                Zbierane przez JukonMedia Mariusz Mischke dane osobowe Użytkowników przetwarzane są w sposób zgodny z zakresem udzielonego nam przez Użytkownika zezwolenia/zgody. Zbiór zgromadzonych danych osobowych Użytkowników traktowany jest jako wydzielona baza danych przechowywana na serwerze właściciela portalu jukonmedia.plw specjalnej strefie bezpieczeństwa zapewniającej właściwą ochronę.
              </ListItem>
              <ListItem>
                Podane przez Użytkownika dane będą przetwarzane zgodnie z treścią ogólnego rozporządzenia o ochronie danych z dnia 27 kwietnia 2016 art. 6 ust. 1 lit a, b i c.
              </ListItem>
              <ListItem>
                Każdemu Użytkownikowi przysługuje prawo dostępu do treści swoich danych i ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania.
              </ListItem>
              <ListItem>
                Użytkownik ma prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych gdy uzna że przetwarzanie danych osobowych dotyczących narusza przepisy ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. Kontakt do Inspektora Ochrony Danych w JukonMedia Mariusz Mischke. email: mariusz@jukonmedia.pl.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischke do w celu wysyłania newslettera/informacji o produktach i usługach, obsługi technicznej portalu angażuje specjalistycznych usługodawców, którzy przetwarzają dane osobowe Użytkowników na zlecenie i zgodnie z zaleceniami JukonMedia Mariusz Mischke we wskazanym celu i zakresie, przestrzegając wymaganych środków bezpieczeństwa.
              </ListItem>
              <ListItem>
                Dane osobowe Użytkowników będą przechowywane do czasu odwołania zgody lub braku aktywności Użytkownika portalu przez okres 12 m-cy.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischke może przy tym indywidualizować treść wysyłanych informacji o towarach, produktach i usługach w taki sposób, aby zawierały zwłaszcza takie informacje, które
                JukonMedia Mariusz Mischkeuzna za szczególnie interesujące dla Użytkowników. Do takiej indywidualizacji informacji JukonMedia Mariusz Mischke uwzględnia m.in. historię oglądanych treści/stron przez Użytkowników portalu jukonmedia.pl.
              </ListItem>
            </List>
          </Section>
          <Section>
            <h2>Polityka plików cookies portalu jukonmedia.pl</h2>
            <List>
              <ListItem>
                Czym są pliki “cookies”? Poprzez pliki “cookies” należy rozumieć dane informatyczne, w szczególności pliki tekstowe przechowywane w urządzeniach końcowych użytkowników przeznaczone do korzystania ze stron internetowych. Pliki te pozwalają rozpoznać urządzenie użytkownika i odpowiednio wyświetlić stronę internetową dostosowaną do jego indywidualnych preferencji. “Cookies” zazwyczaj zawierają nazwę strony internetowej z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
              </ListItem>
              <ListItem>
                Do czego używamy plików “cookies”? Pliki “cookies” używane są w celu dostosowania zawartości stron internetowych do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych. Używane są również w celu tworzenia anonimowych, zagregowanych statystyk, które pomagają zrozumieć w jaki sposób użytkownik korzysta ze stron internetowych co umożliwia ulepszanie ich struktury i zawartości z wyłączeniem personalnej identyfikacji użytkownika.
              </ListItem>
              <ListItem>
                Jakich plików “cookies” używamy? Stosowane są dwa rodzaje plików “cookies”, “sesyjne” oraz “stałe”. Pierwsze z nich są plikami tymczasowymi, które pozostają na urządzeniu użytkownika, aż do wylogowania ze strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). “Stałe” pliki pozostają na urządzeniu użytkownika przez czas określony w parametrach plików “cookies” albo do momentu ich ręcznego usunięcia przez użytkownika. Pliki “cookies” wykorzystywane przez partnerów operatora strony internetowej, w tym w szczególności użytkowników strony internetowej, podlegają ich własnej polityce prywatności.
              </ListItem>
              <ListItem>
                Czy pliki “cookies” zawierają dane osobowe? Dane osobowe gromadzone przy użyciu plików “cookies” mogą być zbierane wyłącznie w celu wykonywania określonych funkcji na rzecz użytkownika. Takie dane są zaszyfrowane w sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.
              </ListItem>
              <ListItem>
                Usuwanie plików “cookies”. Standardowo oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczanie plików “cookies” na urządzeniu końcowym. Ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną obsługę plików “cookies” w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu na urządzenie użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików “cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej). Ograniczenie stosowania plików “cookies”, może wpłynąć na niektóre funkcjonalności dostępne na stronie internetowej.
              </ListItem>
            </List>
          </Section>
        </Col>
      </Row>
    </Container>
    <Container>
      <Footer />
    </Container>
  </>
);

export default PrivacyPolicyPage;
